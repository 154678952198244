// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
import {document} from "postcss";
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();

  /**
   * Counter for flexible section Numbers
   */
  let isIntersecting = true;
  const aboutUsObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if(entry.isIntersecting && isIntersecting) {
        $('.js-number').each(function () {

          let size = $(this).text().split('.')[1]
            ? $(this).text().split('.')[1].length
            : 0;
          $(this)
            .prop('Counter', 0)
            .animate(
              {
                Counter: $(this).text(),
              },
              {
                duration: 2000,
                step: function (func) {
                  $(this).text(parseFloat(func).toFixed(size));
                },
              }
            );
        });
        isIntersecting = false;
      }
    });
  }, { threshold: 1 });
  $( ".flexible-numbers").each(function(elem) {
    aboutUsObserver.observe($( ".flexible-numbers")[elem]);
  });

  $('.js-share').on('click', function () {
    let popup = $(this).next('.js-share-popup');
    if(popup.hasClass('is-active')){
      popup.removeClass('is-active');
    }else {
      popup.addClass('is-active');
    }
  });


});

$('.js-testimonials').slick({
  dots: true,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows:false,
  rows:0,
});
$('.js-event').click('.js-event-toggler', function () {
  $(this).toggleClass('is-active');
  $(this).find('.js-event-bottom').slideToggle();
})

$('.js-dropdown-title').click(function(j) {
  const dropDown = $(this).closest('.js-dropdown').find('.js-dropdown-text');
  $(this).closest('.js-dropdown-wrapper').find('.js-dropdown-text').not(dropDown).slideUp();

  if ($(this).hasClass('is-active')) {
    $(this).removeClass('is-active');
  } else {
    $(this).closest('.js-dropdown-wrapper').find('.js-dropdown-title.is-active').removeClass('is-active');
    $(this).addClass('is-active');
  }
  dropDown.stop(false, true).slideToggle();
  j.preventDefault();
});

$('[data-popup-open]').on('click', function(e) {
  let targeted_popup_class = $(this).attr('data-popup-open');
  $('[data-popup="' + targeted_popup_class + '"]').fadeIn(350);
  $('body').css("overflow", 'hidden');
  e.preventDefault();
});

// Close
$('[data-popup-close]').on('click', function(e) {
  let targeted_popup_class = $(this).attr('data-popup-close');
  $('[data-popup="' + targeted_popup_class + '"]').fadeOut(350);
  $('body').css("overflow", 'auto');
  e.preventDefault();
});
const topBarReservedSpace = () => {
  if($(window).innerWidth() > 640){
  let topBar = $('.header-top-bar .under-bar').innerHeight();
  if(topBar){
    $('.header-top-bar').css("--top-bar-height" , topBar + 'px');
  }else {
    $('.header-top-bar').css("--top-bar-height" , '0px');
  }
  }else {
    $('.header-top-bar').css("--top-bar-height" , '0px');
    return false;
  }
  $('.single-product .ajax-add-to-cart').on('click', function (event) {
    event.preventDefault();

    let productId = $(this).data('product_id');
    $.ajax({
      type: 'POST',
      url: ajax_object.ajax_url,
      data: {
        action: 'add_all_to_cart',
        product_id: productId
      },
      success: function(response) {
        $('.woocommerce-notices-wrapper').append(response.notice);
      },
      error: function(error) {
        console.log(error);
      }
    });
  });
}
$(window).on('load',topBarReservedSpace);
$(window).on('resize',topBarReservedSpace);
/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
